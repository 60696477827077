@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";
@import "aos/dist/aos.css";

// Fonts
@import url("https://use.typekit.net/wji1vqx.css");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*::before,
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body,
html {
    color: $off-white;
    font-family: $font;
    background: $background;
    position: relative;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

hr {
    margin: 2em 0;
}

h1 {
    font-size: clamp(2rem, 0.936rem + 4.863vw, 5.313rem);
    margin: 0.5rem 0;
    margin-block: unset;
}

h2 {
    font-size: clamp(1.85rem, 1.16rem + 3.156vw, 4rem);
    margin: 0.5rem 0;
}

h3 {
    font-size: clamp(1.75rem, 1.349rem + 1.835vw, 3rem);
    margin: 0.5rem 0;
}

h4 {
    font-size: clamp(1.5rem, 1.339rem + 0.734vw, 2rem);
    margin: 0.5rem 0;
}

h5 {
    font-size: clamp(1.25rem, 1.089rem + 0.734vw, 1.75rem);
    font-weight: 400;
    margin: 0.5rem 0;
}

h6 {
    margin: 0.5rem 0;
    font-size: clamp(0.875rem, 0.835rem + 0.183vw, 1rem);
}

a {
    color: inherit;
    font-weight: 500;
}

p {
    font-size: 1rem;
    line-height: 1.7rem;
    margin-bottom: 1rem;
}

ul {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0.5rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

ol {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 0.25rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

blockquote {
    background: rgba(0, 0, 0, 0.03);
    margin: 0 0 1rem;
    padding: 1.25rem;
    border-left: 0.75rem solid $primary;
}

img.fr-bordered {
    border: 5px solid $primary;
}

table {
    &.fr-alternate-rows {
        tr {
            &:nth-child(odd) {
                background: $light-grey;
            }
        }
    }

    tr {
        th {
            background: $primary;
            padding: 0.5rem 1rem;
            text-align: left;
            font-weight: 300;
            font-size: 1.125rem;
            color: $white;
        }

        td {
            padding: 0.5rem 1rem;
            font-weight: 300;
            font-size: 1rem;
            line-height: 24px;

            &.fr-highlighted {
                background: $secondary;
                color: $white;
            }

            &.amount {
                position: relative;
                padding-left: 2rem;
                text-align: right;

                &::before {
                    content: "$";
                    position: absolute;
                    left: 1rem;
                }
            }

            .fr-file {
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                border-radius: 3px;
                border: 0 none;
                text-indent: -999rem;

                &::before {
                    left: 50%;
                    text-indent: initial;
                    transform: translate(-50%, -50%);
                }

                &::after {
                    content: none;
                }

                &:hover {
                    background: $primary;
                }
            }
        }
    }
}

// .vertical-devider {
//     border-left: 1px solid $devider;
//     padding-left: 2rem;
// }

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 20px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;
    .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}

// Notifications
.notification__container {
    background: $primary;
    padding: 1rem;
    text-align: center;
    color: $secondary;

    .notification-content {
        padding: 1rem 0;
    }

    .page-header {
        margin-top: 0;
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// Digital Bridge Website

//Pagination - Tailwind
.pagination {
    @apply w-full text-right px-8;

    li {
        @apply inline-block list-none;

        a,
        span {
            @apply block rounded-sm w-6 h-6 no-underline text-sm text-center align-middle leading-normal text-gray-600;

            &:hover {
                @apply bg-gray-200;
            }
        }

        &.active a,
        &.active span {
            @apply bg-gray-200 text-gray-700;
        }

        &.disabled span {
            @apply text-gray-500;
            &:hover {
                @apply bg-transparent;
            }
        }
    }
}

// General

.container {
    padding: 0 2rem;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
        align-items: flex-start;
    }

    &.reverse {
        @include xs {
            flex-direction: column-reverse;
        }

        .column {
            &:first-child {
                margin-top: 1em;
            }
        }
    }

    .column {
        width: calc(50% - 2em);

        @include md {
            width: 100%;
        }

        &.right {
            @include md {
                margin-top: 2em;
            }
        }
    }
}

main {
    display: block;
    position: relative;
    margin-top: 107px;
}

ul.social-media {
    display: flex;
    padding-left: 0;
    margin: 2em 0 0;

    @include xs {
        margin: 1em 0 0;
    }

    li {
        list-style-type: none;
    }

    a {
        width: 32px;
        height: 32px;
        text-decoration: none;
        margin: 0 0.15em;

        img {
            opacity: 0.75;
            width: 28px;
            transition: transform 0.2s ease-in-out;

            &:hover {
                opacity: 1;
                background: $secondary;
            }

            @include md {
                width: 24px;
            }
        }
    }
}

.g-link {
    font-size: inherit;
    color: $white;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.g-section-title {
    color: $white;
    font-family: $heading;
    font-size: 2rem;
    font-weight: 600;

    @include xs {
        font-size: 1.5rem;
    }
}

h1.page-title {
    display: block;
    color: $primary;
    font-family: $heading;
    font-size: 4.75rem;
    line-height: 92px;
    margin: 0 0 2rem;

    @include md {
        font-size: 3.75rem;
        line-height: 72px;
    }

    @include xs {
        font-size: 2.75rem;
        line-height: 56px;
    }
}

h2.page-subtitle {
    font-size: clamp(1.15rem, 1.089rem + 0.734vw, 1.35rem);
    line-height: clamp(2rem, 1.089rem + 0.734vw, 2.5rem);
    font-weight: 400;
    margin: 0.5rem 0;
}

.two-columns-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;

    @include lg {
        gap: 1.5em;
    }

    @include md {
        grid-template-columns: repeat(1, 1fr);
    }

    .column.left {
        overflow: hidden;
    }
}

.three-columns-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 2em auto;

    @include md {
        grid-template-columns: repeat(2, 1fr);
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }
}

.desktop {
    display: block;

    @include md {
        display: none;
    }
}

.mobile {
    display: none;

    @include md {
        display: block;
    }
}
// bullet list

ul.dotted-list {
    margin: 1em 0;
    padding-left: 1em;

    li {
        line-height: 24px;
        font-weight: 300;
        list-style-type: none;
        margin-bottom: 0.5rem;
        position: relative;
        padding-left: 2rem;

        &::before {
            content: "";
            display: inline-block;
            width: 11px;
            height: 11px;
            background-color: $primary;
            border-radius: 3px;
            position: absolute;
            left: 0;
            top: 0.5rem;
        }
    }
}

// Header

header {
    position: fixed;
    z-index: 101;
    width: 100%;
    top: 0;
    background: $background;

    .row {
        padding: 1em 0;
        align-items: center;
        flex-direction: row !important;
    }

    .header-logo {
        display: block;
        width: 130px;
        height: auto;

        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: center;
        }
    }

    .button.primary.client-support {
        display: flex;
        justify-content: flex-end;

        @include md {
            display: none;
        }
    }

    .desktop-menu {
        nav {
            display: flex;
            align-items: center;

            > ul {
                display: flex;
                margin: 0;
                padding: 0;
                list-style: none;
                gap: 2.25rem;

                > li {
                    position: relative;
                    margin: 0;

                    a,
                    span {
                        display: block;
                        position: relative;
                        padding: 0.375rem 0;
                        color: $white;
                        font-family: $heading;
                        font-weight: 500;
                        font-size: 1.125rem;
                        text-decoration: none;
                        z-index: 1;

                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            background: $primary;
                            width: 0;
                            height: 3px;
                            transition: all 0.3s ease;
                        }
                    }

                    &:hover > a,
                    &:hover > span,
                    & > a.active,
                    & > span.active {
                        color: $primary;

                        &::after {
                            width: 100%;
                        }
                    }

                    &:hover {
                        ul {
                            transform: translate(0, 0);
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    ul {
                        position: absolute;
                        top: calc(100% + 0.5rem);
                        left: 0;
                        background: $secondary-background;
                        width: max-content;
                        margin: 0;
                        padding: 0.25rem 0;
                        box-shadow: 0 3px 5px rgba(38, 42, 47, 0.15);
                        transform: translateY(1rem);
                        transition: all 0.25s ease;
                        visibility: hidden;
                        list-style: none;
                        opacity: 0;
                        z-index: 0;

                        &::before {
                            content: "";
                            position: absolute;
                            inset: -0.5rem 0 0 0;
                            height: 0.5rem;
                        }

                        li {
                            margin: 0;

                            a {
                                display: block;
                                padding: 0.5rem 1rem;
                                transition: all 0.25s ease;
                                text-transform: none;
                                text-align: left;
                                font-size: 1rem;
                                color: $white;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background: $primary;
                                    width: 0;
                                    height: 100%;
                                    transition: all 0.25s ease;
                                }

                                &::after {
                                    content: none;
                                }

                                &:hover,
                                &.active {
                                    color: $primary;

                                    &::before {
                                        width: 5px;
                                    }
                                }
                            }

                            ul {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: flex;
    justify-content: center;
    flex: 1;

    @include md {
        display: none;
    }
}

.mobile-menu {
    display: none;

    @include md {
        position: relative;
        display: block;
        z-index: 101;
    }

    .row {
        align-items: center;

        @include md {
            flex-direction: row;
        }
    }

    .logo-link {
        z-index: 1000;
    }

    .logo {
        width: 12em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include md {
        width: 30px;
        height: 30px;
        display: block;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $primary;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    z-index: 100;

    &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    nav {
        display: block;
        position: fixed;
        top: 107px;
        left: 50%;
        right: 0;
        bottom: 0;
        transform: translate(-50%, 0);
        background: $background;
        width: 100%;
        height: 100%;
        padding: 1rem 0 7em 0;
        transition: all 0.3s ease;
        overflow-x: scroll;
        z-index: 2;

        ul {
            display: flex;
            position: relative;
            top: 50%;
            width: 100%;
            margin: 0;
            padding: 0;
            transform: translateY(-50%);
            flex-direction: column;
            text-align: center;
            font-weight: 400;
            list-style: none;
            font-size: 24px;

            li {
                position: relative;
                opacity: 0;
                margin-bottom: 0;
                padding: 0.15em 1.25em;

                a {
                    display: block;
                    font-family: $font;
                    width: 100%;
                    padding: 0.5em 0.5em;
                    transition: all 0.25s ease;
                    text-decoration: none;
                    font-size: 1.5rem;
                    color: $white;

                    &:hover {
                        background: hsla(0, 0%, 100%, 0.15);
                        color: $white;
                    }
                }

                ul {
                    display: none;
                    margin: 0 0 0.5em;

                    li {
                        ul {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}

// Footer

footer {
    width: 100%;
    padding: 0 0 1rem 0;

    ul {
        list-style-type: none;
        padding-left: 0;
    }
    a {
        text-decoration: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        .footer-top-row {
            display: flex;
            flex-direction: row;
            height: 19rem;
            border-bottom: $devider 1px solid;
            @include md {
                height: unset;
            }
            .column.left {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                @include md {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                }

                .footer-column {
                    padding-right: 2rem;

                    @include md {
                        width: 100%;
                    }
                }
                .footer-col-2 {
                    border-left: 1px solid $devider;
                    padding-left: 2rem;

                    @include md {
                        order: 1;
                        border-left: none;
                        padding-left: unset;
                        border-bottom: $devider 1px solid;
                        margin: 1rem 0;
                    }
                }

                .footer-col-1 {
                    padding-left: 1rem;

                    flex-basis: 55%;

                    @include md {
                        order: 2;
                        padding-left: unset;
                        flex-basis: unset;
                    }
                    .social-media {
                        li {
                            margin-bottom: 0;
                            @include md {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }

            .column.right {
                height: 100%;
                width: auto;

                .squares-logo {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    padding-right: 1rem;
                }
                @include md {
                    display: none;
                }
            }
        }
    }
    .footer-bottom-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 1rem 0 1rem;

        @include md {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 1rem 0;
        }

        .terms {
            position: relative;
            padding-right: 1.1rem;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 1px;
                height: 100%;
                background-color: $white;
            }
        }

        @include md {
            .terms,
            .privacy,
            .credit {
                font-size: 0.75rem;
                margin: 0.1rem 0;
            }
        }
    }
}

// -- Pre footer and Hero section

.pre-footer,
.intro-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $devider;
    border-top: 1px solid $devider;
    padding: 2rem 1rem;
    @include md {
        flex-direction: column;
        padding: 2rem 0;
    }
    .prefoot-col-1,
    .intro-col-1 {
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: flex-start;
        text-align: left;
        @include md {
            width: 100%;
        }
    }
    .prefoot-col-2,
    .intro-col-2 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        @include md {
            width: 100%;
            align-items: flex-start;
        }

        .primary {
            margin: 1rem 0;
        }
    }
}

// Categories buttons

.category-slider {
    display: flex;
    align-items: top;
}

ul.category-bar {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    margin: 0;
    list-style-type: none;

    li {
        margin-right: 8px;
        white-space: nowrap;

        @include xs {
            margin-right: 3px;
        }
        a {
            text-decoration: none;
            border: 1.5px solid $off-white;
            border-radius: 20px;
            padding: 1px 8px;
            transition: all 0.25s ease;

            &.active {
                a {
                    background: $primary;
                    color: $background;
                    border: $primary 1.5px solid;
                }
            }

            &:hover {
                background-color: $primary;
                color: $background;
                border: $primary 1.5px solid;
            }
        }
    }
}

// -- Homepage

#home-hero-section {
    width: 100%;
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-width: 43em;
    }

    .hero-buttons {
        margin: 2rem 0;
        flex-wrap: wrap;
        display: flex;
        gap: 1rem;

        &a {
            white-space: nowrap;
        }
    }
}

#work-section-home {
    padding-top: 3rem;
    padding-bottom: 2rem;
}

// -- Logo Slider

.logo-slider-title {
    display: block;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}

.logo-slider {
    margin: 3rem auto 1rem;
    .slick-list {
        width: 100%;
        max-width: 1051px;
        margin: 0 auto;
    }
    .slick-track {
        display: flex;
        // justify-content: center;
        // margin: 0 auto;
        width: 100%;
    }

    .slick-slide {
        height: 175px;
        background: $off-white;
        border-radius: 15px;
        margin: 0 1em;

        @include lg {
            height: 10em;
        }

        &::before {
            background-color: transparent !important;
            opacity: 1 !important;
        }

        .slide-media {
            opacity: 1;
            width: 120px;
            height: 100%;
            margin: 0 auto;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }
}
// testimonials section
#testimonials-header {
    margin-top: 6rem;

    @include md {
        margin-top: 3rem;
    }
}
#slider-container {
    position: relative;
    padding: 0;
    margin-bottom: 6rem;

    @include md {
        margin-bottom: 3rem;
    }

    .slider-arrow {
        position: absolute;
        top: 50%;
        background-color: #bdd8f250;
        border: #bdd8f250 1px solid;
        border: none;
        padding: 0.5rem;
        height: 3rem;
        width: 3rem;
        border-radius: 100%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease-in-out, visibility 200ms ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: $primary;
            border: $primary 1px solid;
        }
        img {
            width: 1.5rem;
            height: 1.5rem;
            object-fit: contain;
        }

        @include md {
            display: none;
        }
    }

    &:hover .slider-arrow {
        opacity: 1;
        visibility: visible;
    }

    .slider-arrow-left {
        left: 1rem;
        transform: rotate(180deg);

        @include lg {
        }
    }
    .slider-arrow-right {
        right: 1rem;
    }
}
#testimonials {
    margin: 1rem auto;

    .testimonials-slider {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-top: 6rem;
        overscroll-behavior-inline: contain;
        user-select: none;
        cursor: grab;

        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        &:active {
            cursor: grabbing;
        }

        .snaps-inline {
            scroll-snap-type: inline mandatory;
            scroll-padding-inline: var(--spacer, 1rem);
        }
        .snaps-inline > * {
            scroll-snap-align: start;
        }

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 0 0 auto;
            scroll-snap-align: start;
            width: 22rem;
            height: 29rem;

            padding: 2rem;
            margin: 1rem;
            border: 2px solid $light-background;
            border-radius: 20px;

            @include md {
                width: 20rem;
            }

            &.filled {
                background: $light-background;
                color: $background;
                margin-top: -4rem;
                .author-wrapper {
                    border-top: $secondary-background 1.5px solid;
                }
            }

            &.sheer {
                background: $secondary-background;
                .author-wrapper {
                    border-top: $devider 1.5px solid;
                }
            }

            .body-wrapper {
                display: flex;
                flex-direction: column;
                padding: 1rem 0;
                .testimonials-body {
                    padding: 0 1.4rem;
                }
            }

            .author-wrapper {
                display: flex;
                flex-direction: column;
                padding-top: 1rem;
            }
        }
    }
}

// -- Work page

.filter-select {
    padding: 1rem 0;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;

    #works-sort {
        cursor: pointer;
        border: none;
        &:focus {
            outline: none;
        }
    }

    select {
        font-size: 1rem;

        @include xs {
            font-size: 0.8rem;
        }
    }
}

#our-works-grid,
#work-section-home,
#related-work {
    .work-header {
        margin-top: 1rem;
    }
    .two-columns-grid {
        margin: 2em auto;
        gap: 3rem 1.5rem;
        // grid-column: span 1;
        @include md {
            gap: 3rem;
        }
        .featured-2-col {
            grid-column: span 2;
        }
        @include xs {
            margin: 1em auto;
        }

        @include md {
            .featured-2-col {
                grid-column: unset;
            }
        }

        .work-base-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 30em;
            border-radius: 30px;
            background: center / cover no-repeat;
            cursor: pointer;
            overflow: hidden;
            padding: 2rem 4rem;

            @include lg {
                height: 22em;
            }

            @include xs {
                height: 18em;
            }
            .work-top-img {
                object-fit: contain;
                max-width: 100%;
                max-height: 100%;
                border-radius: 20px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
            }

            .desktop-screen {
            }

            .mobile-screen {
            }
        }

        .work-item-text {
            .work-text-header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                padding: 0.5rem 0;
                border-bottom: 1px solid $devider;
            }
            .work-item-summary {
                padding: 1rem 0 0 0;
            }
        }
    }
}

#our-works-grid {
    .button.border {
        display: block;
        margin: 4em auto 6em;
    }
}

// -- Case Study

.case-study-header {
    position: relative;
    background: transparent no-repeat center center / cover;
    width: 100%;
    height: 400px;
}

#case-study-title-block {
    border-bottom: 1px solid white;
    padding-bottom: 2rem;

    .arrow-up {
        transform: rotate(-38deg);
    }
    #slider-right {
        justify-content: flex-end;
        padding-bottom: 3rem;
    }
    .work-title {
        padding-bottom: 2rem;
    }
}

#case-study-content {
    #background {
        grid-template-columns: 70% 27%;
        margin: 4rem auto;
        padding-bottom: 2rem;
        @include md {
            grid-template-columns: repeat(1, 1fr);
            margin: 2rem auto;
            padding-bottom: 0;
        }

        .background-text-content {
            padding-right: 3rem;
            @include md {
                padding-right: unset;
            }
            .heading-h4 {
                padding-bottom: 1rem;
            }
        }

        .case-study-sidebar {
            flex-basis: 30%;
            padding: 2rem 0;
            flex-basis: 30%;

            .case-study-logo {
                padding-bottom: 2rem;
            }

            img {
                max-width: 300px;
                max-height: 140px;
            }

            .vertical-devider {
                border-left: $devider 1px solid;
                padding-left: 3rem;
                @include md {
                    margin: 0 2rem;
                }

                @include md {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    #approach {
        grid-template-columns: 30% 67%;
        margin: 4rem auto;
        @include md {
            grid-template-columns: repeat(1, 1fr);
            margin: 2rem auto;
        }

        .mobile-img-block {
            display: flex;
            align-items: center;
            @include md {
                justify-content: center;
                margin-bottom: 2rem;
            }
            .mobile-img-wrapper {
                width: 80%;
                height: auto;

                @include md {
                    width: 50%;
                }
                .mobile-display {
                    width: 100%;
                    height: auto;
                    border-radius: clamp(1.25rem, 0.804rem + 1.19vw, 1.875rem);
                }

                img {
                    max-width: 100%;
                }
            }
        }
        .approach-text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 60%;

            .heading-h4 {
                padding-bottom: 1rem;
            }
        }
    }

    .desktop-img-block {
        padding: clamp(1rem, -0.429rem + 3.81vw, 3rem)
            clamp(4rem, 2.571rem + 3.81vw, 6rem);
        width: 100%;

        @include md {
            padding: 2rem 1rem;
        }
        .desktop-display {
            width: 100%;
            height: auto;
            border-radius: clamp(1.25rem, 0.804rem + 1.19vw, 1.875rem);
        }
    }

    #result {
        grid-template-columns: 70% 30%;
        margin: 2rem auto;
        @include md {
            grid-template-columns: repeat(1, 1fr);
            margin: 2rem auto;
        }
        .heading-h4 {
            padding-bottom: 1rem;
        }

        img {
            display: block;
            margin-inline: auto;
        }

        .text-button {
            max-width: 10rem;
            padding-top: 2rem;
        }
        .arrow-up {
            transform: rotate(-38deg);
        }
    }
}

#case-study-content {
    #our-approach {
        > div:first-child {
            flex-basis: 30%;
        }

        > div:last-child {
            flex-basis: 70%;
        }
    }
}

.work-text-contents {
    h2.content-title {
        font-weight: 500;
        text-transform: uppercase;
    }

    .content-block {
        margin: 4em auto;

        @include md {
            margin: 2em auto;
        }
    }
}

#related-work {
    margin: clamp(2rem, -0.857rem + 7.619vw, 6rem) auto;

    .work-header {
        border-top: $devider 1px solid;
        padding-top: clamp(2rem, -0.857rem + 7.619vw, 6rem);
    }
}
// -- About Page

#about {
    .section {
        margin: 0 auto;
        padding: 4rem 1rem;

        @include xs {
            padding: 1rem 1rem;
            .container {
                padding: 0;
            }
        }
        .title-container {
            padding-block: 1.5rem;
        }
    }
    #values {
        background-color: $secondary-background;
        .dotted-list {
            padding-left: 3rem;

            li {
                line-height: clamp(2.25rem, 1.089rem + 0.734vw, 2.75rem);

                &::before {
                    content: "";
                    top: 0.8rem;
                }
            }
        }
    }
    #background,
    #values {
        .img-wrapper {
            width: 100%;
            height: 600px;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 30px;
            }
        }
        .background-col2,
        .values-col2 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    #approach {
        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .img-wrapper {
                width: 60%;
                padding: 2rem;
                @include xs {
                    width: 90%;
                }
                .approach-img {
                    object-fit: contain;
                    width: 100%;
                    height: auto;
                }
            }

            .approach-text {
                max-width: 30rem;
                padding: 1rem 2rem;
                @include xs {
                    padding: 1rem 0;
                }
            }
        }
    }
}

.indent-1col {
    max-width: 60rem;
    border-left: $devider 1px solid;
    // margin-left: 7rem;
    margin-bottom: 3rem;
    padding-left: 2rem;

    .heading-h5,
    .text-button {
        padding-bottom: 0.5rem;
    }

    @include md {
        margin-left: 0;
        margin-bottom: 2rem;
    }
    @include xs {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
    }
}

#about-our-team {
    background-color: $secondary-background;
    padding: 0 2rem;

    .team-title {
        padding-top: 3rem;
    }

    .three-columns-grid {
        align-items: baseline;
        gap: 2em;
        padding: 2rem 0;

        .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .people-img {
                display: block;
                width: 10em;
                height: 10em;
                margin-bottom: 1em;
                object-fit: cover;
                border-radius: 10%;

                @include xs {
                    width: 8em;
                    height: 8em;
                }
            }

            .people-name {
                display: block;
                font-size: 1.5rem;
                font-weight: 600;
                font-family: $heading;
                margin-bottom: 0.25em;

                @include xs {
                    font-size: 1.25rem;
                }
            }

            .people-title {
                display: block;
                font-size: 1rem;
                font-family: $heading;
                text-transform: uppercase;
                margin-bottom: 1em;
            }

            p.people-desc {
                font-size: 1rem;
                font-family: $heading;
            }
        }
    }
}

// -- Expertise page

#expertise #cms-page-contents {
    margin: 4em auto;
    background: $light-background;

    .container {
        h2,
        p {
            color: $background;
        }
    }
}

#expertise-grid {
    .two-columns-grid {
        gap: 2em;
        margin: 2em auto 4em;

        @include xs {
            grid-template-columns: repeat(1, 1fr);
        }

        .column {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 0.5rem;
            border-radius: 30px;
            border: 1px solid $white;
            padding: 3rem;

            .card-title {
                padding-bottom: 2rem;
                border-bottom: $devider 1px solid;
            }

            .button-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                .text-button {
                    padding-bottom: 1rem;
                    padding-right: 1rem;
                }
            }
            @include lg {
                padding: 1.5rem;
            }
            @include md {
                padding: 2rem;
            }

            @include xs {
                padding: 1rem;
                .dotted-list {
                    padding-top: 1rem;
                    padding: 1rem 1rem;
                }
            }
        }
    }
}

#expertise-cms-text {
    display: none;
    padding: 2em 0;

    @include xs {
        padding: 1em 0;
    }

    .fr-fic {
        border-radius: 30px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    }
}

// Expertise show page
#design-experience,
#web-development,
#website-management,
#strategy-digital-marketing {
    #cms-page-header {
        padding-block: 4rem;
        @include md {
            padding-block: 1rem;
        }
    }
    #cms-page-contents {
        background-color: $secondary-background;
        max-width: unset;
        padding-block: 2rem;
    }
    .pre-footer {
        border-top: none;
    }
}
#design-experience-1col-gallery,
#web-development-1col-gallery,
#website-management-1col-gallery,
#strategy-digital-marketing-1col-gallery {
    max-width: 1440px;
    padding: 0 2rem;
    @include lg {
        padding: 0;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        padding-left: unset;

        @include md {
            grid-template-columns: repeat(2, 1fr);
        }
        @include xs {
            grid-template-columns: repeat(1, 1fr);
        }

        li {
            padding-left: unset;
            &:before {
                display: none;
            }

            .image,
            .svg {
                display: flex;
                flex-direction: column;
                justify-content: center;

                img {
                    height: 50px;
                    width: auto;
                    margin-block: 2rem;
                }

                h4 {
                    font-size: 1rem;
                    line-height: 1.7rem;
                    font-weight: 700;
                    margin: 1rem 0;
                }

                figcaption {
                    font-size: 1rem;
                    line-height: 1.75rem;
                }
            }
        }
    }
}

// -- Blog Index page

#blog-index-page {
    margin: 0 auto;

    .featured-blog {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3em;
        margin-bottom: 1em;
        border-bottom: 1px solid $white;

        @include md {
            flex-direction: column;
        }
        .img-wrapper {
            width: 100%;
            .featured-blog-img {
                display: block;
                width: 100%;
                max-width: 600px;
                height: 425px;
                object-fit: cover;
                border-radius: 30px;
                padding-bottom: 1rem;

                @include lg {
                    max-width: 30em;
                    height: 20em;
                }

                @include md {
                    max-width: 100%;
                    height: 20em;
                }
            }
        }

        .blog-item-text {
            position: relative;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            text-align: left;
            z-index: 2;
            padding: 1em;
            margin-left: 2em;

            @include md {
                margin: 1em 0 0 0;
                padding: 0;
            }

            h4.blog-item-name {
                display: block;
                font-family: $heading;
                font-size: 1.5rem;
                margin-bottom: 0.5em;
            }
            // .blog-item-summary {
            //     padding-bottom: 1rem;
            // }

            .button.border {
                margin: 1rem 0;
            }
        }
    }

    .three-columns-grid {
        row-gap: 3rem;

        .blog-item {
            text-decoration: none;
        }
        .blog-img {
            display: block;
            width: 100%;
            height: 22em;
            border-radius: 30px;
            background: center / cover no-repeat;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 2rem;

            @include md {
                margin-bottom: 1.5rem;
            }
        }

        .blog-item-name {
            padding: 0.5 0 0.7rem 0;
        }
    }
}

// -- Blog Show page

#blog-single-page-header {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include md {
        height: 15em;
    }
}

#blog-page-content {
    .blogpage-intro {
        border-bottom: 1px solid $devider;
        padding-bottom: 3rem;

        .social-links {
            display: flex;
            align-items: center;
            margin-top: 2rem;

            .share-label {
                margin-right: 2.5rem;
            }

            .facebook,
            .linkedin {
                width: 20px;
                height: auto;
            }

            .facebook {
                margin-right: 1rem;
            }
        }
    }
    .blogpost-body {
        padding: 4rem 0;
        color: $off-white !important;

        h1,
        h2 {
            font-size: clamp(1.5rem, 1.339rem + 0.734vw, 2rem);
            margin: 1.2rem 0;
            font-weight: 700;
        }

        h3,
        h4,
        h5,
        h6 {
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.7rem;
            margin: 0.7rem 0;
        }

        p {
            font-size: 1rem;
            line-height: 1.7rem;
        }
    }
}

#related-posts {
    margin-bottom: 6rem;
    margin-top: 3rem;

    .three-columns-grid {
        row-gap: 2em;

        .blog-img {
            display: block;
            width: 100%;
            height: 22em;
            border-radius: 30px;
            background: center / cover no-repeat;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 2rem;
        }

        .blog-item-category {
        }
        .blog-item-name {
            padding: 0.5 0 0.7rem 0;
        }

        // .blog-item-summary {
        //     padding-bottom: 1.5rem;
        // }
    }
}

//     .container {
//         width: 100%;
//         z-index: 2;
//     }

//     .page-header-contents {
//         display: flex;
//         flex-direction: column;
//         margin: 0;
//         width: 100%;
//         max-width: 800px;
//         text-align: left;
//         padding-bottom: 4em;

//         @include md {
//             padding-bottom: 2em;
//         }

//         span.blog-category {
//             display: block;
//             font-family: $heading;
//             font-size: 1rem;
//             text-transform: uppercase;
//             margin-bottom: 1em;

//             @include xs {
//                 margin-bottom: 0.5em;
//             }
//         }

//         h1.blog-page-title {
//             margin: 0;
//             font-size: 3rem;

//             @include md {
//                 font-size: 2.125rem;
//             }

//             @include xs {
//                 font-size: 2rem;
//             }
//         }
//     }
// }

// #blog-page-content {
//     .blog-page-intro {
//         display: block;
//         margin: 1em 0;

//         .row {
//             justify-content: flex-end;

//             @include md {
//                 justify-content: flex-start;
//             }

//             .column.right {
//                 width: auto;
//                 margin: 0 !important;

//                 .social-media-links {
//                     display: flex;

//                     .blog-tag-p {
//                         padding-right: 1em;
//                         margin-bottom: 0;
//                     }

//                     p {
//                         margin-bottom: 0;
//                     }

//                     a {
//                         margin-right: 0.5em;
//                         text-decoration: none;

//                         img {
//                             height: 18px;
//                         }
//                     }
//                 }
//             }
//         }
//     }

//     .blog-page-content {
//         display: block;
//         width: 100%;
//         max-width: 1200px;
//         text-align: left;
//         margin: 2em auto 4em;
//     }
// }

// #related-blogs {
//     margin: 4em auto;
//}

// -- Contact page

#contact {
    .contact-header {
        background-color: $secondary-background;
        padding: 2rem 0;

        @include md {
            padding: 1rem 0;
        }
        #cms-page-header {
            margin: 2em auto 3em;
        }
        .page-subtitle {
            font-size: clamp(1.25rem, 1.089rem + 0.734vw, 1.55rem);
            line-height: clamp(2rem, 1.089rem + 0.734vw, 2.5rem);
            font-weight: 400;
            margin: 0.5rem 0;
            text-transform: unset;
        }
    }
    #contact-details {
        padding-top: 4rem;
    }
    .contact-details,
    .contact-socials {
        li,
        a {
            text-decoration: none;
        }
    }
}

#contact-details {
    text-align: left;
    padding-bottom: 3rem;

    #map-canvas {
        text-align: left;
        color: $black !important;
        border-radius: 30px;
        height: 30rem;
        background-color: $primary;

        h2 {
            font-size: 1rem;
        }

        p {
            font-size: 1rem;
        }
    }

    ul.contact-details,
    ul.contact-socials {
        padding-left: 0;
        margin-top: 1.5em;
        li {
            margin-bottom: 1.5rem;
        }

        .heading-h6 {
            padding-left: 3rem;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 3px;
                width: 2rem;
                height: 1.4rem;
                margin: 0;
            }
        }

        .email {
            &::before {
                background: url("/img/icons/email.svg") center / contain
                    no-repeat;
            }
        }
        .phone {
            &::before {
                background: url("/img/icons/phone.svg") center / contain
                    no-repeat;
                color: $primary;
            }
        }
        .pin {
            &::before {
                background: url("/img/icons/pin.svg") center / contain no-repeat;
            }
        }

        .facebook {
            &::before {
                background: url("/img/icons/facebook-lightblue.svg") center /
                    contain no-repeat;
            }
        }
        .instagram {
            &::before {
                background: url("/img/icons/instagram-lightblue.svg") center /
                    contain no-repeat;
            }
        }

        .linkedin {
            &::before {
                background: url("/img/icons/linkedin-lightblue.svg") center /
                    contain no-repeat;
            }
        }

        li {
            width: 100%;
            position: relative;
            list-style-type: none;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     width: 60px;
            //     height: 100%;
            //     margin: 0; }

            // &.phone {
            //     &::before {
            //         background-image: url('/img/icons/email.svg');
            //         background-size: 24px 24px;
            //     }
            // }

            // &.email {
            //     &::before {
            //         background-image: url('/img/icons/email.svg');
            //         background-size: 24px 24px;
            //     }
            // }

            // &.fax {
            //     &::before {
            //         background-image: url('/img/icons/email.svg');
            //         background-size: 24px 24px;
            //     }
            // }
        }
    }
}
#contact-quote {
    background-color: $secondary-background;
    padding-top: 5rem;
    padding-bottom: 5rem;

    .form-title {
        margin-bottom: 2rem;
    }

    .span-2 {
        grid-column: span 2;
    }

    #message {
        height: 9rem;
    }
    .button.primary {
        margin-top: 2rem;
        padding-right: 5rem;
        padding-left: 5rem;
    }

    @include md {
        .two-columns-grid {
            gap: 0;
        }
    }
}

#contact-faq-bg {
    background: $light-background;

    #contact-faq {
        align-items: center;
        color: $background;
        margin-top: 0;
        padding: 2em 0;

        .col-title {
            display: none;
        }

        .toggle .toggle-inner {
            a {
                color: $background;
            }
        }
    }
}

// -- CMS Page

#cms-page-header {
    margin: 0 auto 2em;
    text-align: left;
    padding-top: 2rem;
    @include xs {
        margin: 3em auto 3em;
        padding-top: 0;
    }
}

#cms-page-contents {
    margin: 2em auto;
}

.content-block {
    margin: 4em auto;

    @include md {
        margin: 2em auto;
    }

    &.cols-1 {
        div.text {
            width: 100%;
            display: flex;
            justify-content: space-between;

            @include md {
                flex-direction: column;
                justify-content: flex-start;
            }

            h2 {
                flex-basis: 50%;
                width: 100%;
                padding: 0 3em 0 0;

                @include md {
                    flex-basis: 100%;
                    padding: 0;
                }
            }

            .col-text-contents {
                flex-basis: 50%;
                width: 100%;
                padding: 0 3em 0 0;

                p {
                    margin-top: 0;
                }

                @include md {
                    flex-basis: 100%;
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    &.cols-2 {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include md {
            padding: 0;
        }

        div {
            flex-basis: 50%;
            width: 100%;
            padding: 0 3em 0 0;

            &:last-child {
                padding: 0;
            }

            @include md {
                flex-basis: 100%;
                width: 100%;
                padding: 0;
            }

            img {
                width: 100%;
                max-height: 400px;
                margin: 0;
            }
        }
    }

    &.cols-3 {
        display: flex;
        flex-wrap: wrap;

        div {
            flex-basis: 33.3333333%;
            width: 100%;
            padding: 0 2em 0 0;

            &:last-child {
                padding: 0;
            }

            @include md {
                flex-basis: 50%;
                width: 100%;
                padding: 0 3em 0 0;
            }

            @include xs {
                flex-basis: 100%;
                padding: 0;
            }

            img {
                max-height: 400px;
            }
        }
    }
}

.content-block {
    // h2.col-title {
    //     font-family: $heading;
    //     line-height: 32px;
    //     margin: 0 0 1em;

    //     @include xs {
    //         font-size: 1.5rem;
    //         margin: 0;
    //     }
    // }

    // p {
    //     font-size: 1.125rem;
    //     line-height: 26px;
    //     margin: 1.5em 0;

    //     @include xs {
    //         font-size: 1rem;
    //         line-height: 24px;
    //     }
    // }

    .text {
        text-align: left;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $heading;
            font-weight: 600;
            margin: 0.75rem auto;
        }

        h1 {
            line-height: 1.2;
            //font-size: clamp(256.25%, 6.5vw, 300%);
        }

        h2 {
            line-height: 1.2;
            //font-size: clamp(212.5%, 6vw, 250%);
        }

        h3 {
            line-height: 1.2;
            //font-size: clamp(175%, 5.5vw, 206.25%);
        }

        h4 {
            //font-size: clamp(143.75%, 5vw, 168.75%);
        }

        h5 {
            //font-size: clamp(118.75%, 4.5vw, 137.5%);
        }

        h6 {
            //font-size: clamp(100%, 4vw, 112.5%);
        }

        a {
            color: $primary;
        }

        a.button {
            color: $white;
            padding: 0.5rem 1.25rem;

            &:hover {
                color: $secondary;
            }
        }
    }

    ul {
        margin: 1em 0;
        padding-left: 1em;

        li {
            line-height: 24px;
            font-weight: 300;
            list-style-type: none;
            margin-bottom: 0.5rem;
            position: relative;
            padding-left: 2rem;

            &::before {
                content: "";
                display: inline-block;
                width: 11px;
                height: 11px;
                background-color: #0d273b;
                border-radius: 3px;
                position: absolute;
                left: 0;
                top: 0.5rem;
            }
        }
    }

    ol {
        margin: 0 0 1.5rem;
        padding-left: 1.5em;
        list-style: decimal;

        li {
            margin-bottom: 0.5em;
            padding-left: 0.75rem;
            font-weight: 300;
        }
    }

    hr {
        background: #dfdfdd;
        height: 2px;
        margin: 3rem 0;
        border: 0;
    }

    blockquote {
        background: rgba($black, 0.03);
        margin: 0 0 1rem;
        padding: 1.25rem;
        border-left: 0.75rem solid $primary;

        > :last-child {
            margin-bottom: 0;
        }
    }

    table {
        &.fr-alternate-rows {
            tr {
                &:nth-child(odd) {
                    background: $light-grey;
                }
            }
        }

        tr {
            th {
                background: $dark-grey;
                padding: 0.5rem 1rem;
                text-align: left;
                font-weight: 300;
                font-size: 112.5%;
                color: $white;
            }

            td {
                padding: 0.5rem 1rem;
                font-weight: 300;
                font-size: 112.5%;

                &.fr-highlighted {
                    background: $primary;
                    color: $white;
                }

                &.amount {
                    position: relative;
                    padding-left: 2rem;
                    text-align: right;

                    &::before {
                        content: "$";
                        position: absolute;
                        left: 1rem;
                    }
                }

                .fr-file {
                    display: block;
                    width: 2.5rem;
                    height: 2.5rem;
                    padding: 0;
                    border-radius: 3px;
                    border: 0 none;
                    text-indent: -999rem;

                    &::before {
                        left: 50%;
                        text-indent: initial;
                        transform: translate(-50%, -50%);
                    }

                    &::after {
                        content: none;
                    }

                    &:hover {
                        background: $primary;
                    }
                }
            }
        }
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }

    iframe,
    video {
        aspect-ratio: 16 / 9;
        width: 100% !important;
        height: 100%;
    }

    a {
        font-weight: 600;
    }

    a.fr-file {
        position: relative;
        display: inline-block;
        background: none;
        padding: 0.5rem 1.5rem 0.5rem 3.25rem;
        border-radius: 10rem;
        border: 2px solid $black;
        letter-spacing: 0;
        overflow: hidden;
        font-size: 100%;
        color: $black;
        z-index: 1;

        &::before {
            content: "";
            display: flex;
            position: absolute;
            top: calc(50% - 1px);
            left: 1.25rem;
            background: $black;
            mask: url("/img/icon-download.svg") center center / 1.125rem
                no-repeat;
            width: 1.25rem;
            height: 1.25rem;
            padding: 0;
            transform: translate(0, -50%);
            justify-content: center;
            align-items: center;
            font-weight: 300;
            z-index: 0;
        }

        &::after {
            content: "";
            position: absolute;
            top: 3px;
            bottom: 3px;
            left: 3px;
            background: lighten($primary, 30%);
            width: 0;
            height: auto;
            border-radius: 10rem;
            transition: all 0.25s ease;
            z-index: -1;
        }

        &:hover {
            &::after {
                width: calc(100% - 6px);
            }
        }
    }
}

.centered-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#loose-section-title {
    margin: 2em auto 0;
}

// Animation

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

// Overrides

.bg-secondary {
    background-color: $secondary-background;
    padding-block: 5rem;
    @include md {
        padding-block: 3rem;
    }
}

.breadcrumbs {
    display: flex;
    align-items: center;
    padding: 2rem 0 2rem 0;
    a {
        text-decoration: none;
    }
    img {
        height: 0.7rem;
        width: auto;
        margin: 0 2rem;
    }
}
// Typography Overrides

.heading-h1 {
    font-size: clamp(2rem, 0.936rem + 4.863vw, 5.313rem);
    margin: 0.5rem 0;
    margin-block: unset;
}

.heading-h2 {
    font-size: clamp(1.85rem, 1.16rem + 3.156vw, 4rem);
    margin: 0.5rem 0;
    font-weight: 700;
}

.heading-h3 {
    font-size: clamp(1.75rem, 1.349rem + 1.835vw, 3rem);
    margin: 0.5rem 0;
    font-weight: 700;
}

.heading-h4 {
    font-size: clamp(1.5rem, 1.339rem + 0.734vw, 2rem);
    margin: 0.5rem 0;
    font-weight: 700;
}

.heading-h5 {
    font-size: clamp(1.15rem, 1.089rem + 0.734vw, 1.35rem);
    line-height: clamp(2rem, 1.089rem + 0.734vw, 2.5rem);
    font-weight: 400;
    margin: 0.5rem 0;
}

.heading-h6 {
    font-size: 1rem;
    line-height: 1.7rem;
    font-weight: 700;
    margin: 0.5rem 0;
}

.body-1 {
    font-size: 1.25rem;
    line-height: 2.5rem;
}

.body-2 {
    font-size: 1rem;
    line-height: 2rem;
}

.subhead1 {
    font-size: 1.5rem;
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: 500;
}

.subhead2 {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-weight: 500;
}

.button-text {
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.text-center {
    text-align: center;
}
.text-blue {
    color: $background;
}
// btns new - move later

.text-button {
    display: flex;
    align-items: center;
    .links-arrow {
        height: 1rem;
        width: auto;
        margin-left: 0.5em;
    }

    &:hover {
        .links-arrow {
            transform: translate(5px);
            transition: 0.2s ease-in-out;
        }
        // .links-arrow {
        //     transform: rotate(-30deg);
        //     transition: 0.3s ease-in-out;
        // }
    }
}

// Media Queries
@media screen and (max-width: 1000px) {
    #nav-button {
        display: none;
    }
}

@media screen and (min-width: 900px) {
    .case-study-header {
        height: 480px;
    }
}
