button,
.button {
    display: inline-flex;
    width: fit-content;
    padding: $btn-padding;
    transition: 0.2s ease-in-out;
    backface-visibility: hidden;
    appearance: none;
    cursor: pointer;
    font-family: $heading;
    font-size: clamp(1rem, 0.835rem + 0.183vw, 1rem);
    font-weight: 600;
    letter-spacing: 1px;
    line-height: inherit;
    text-decoration: none;
    border-radius: 30px;
    color: inherit;


    &:hover {
        transition: 0.2s ease-in-out;
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        background: $primary;
        color: $secondary;
        border: $primary 2px solid;

        &:hover {
            background: transparent;
            color: $white;
            border: $off-white 2px solid;
        }
    }

    &.secondary {
        background: $background;
        color: $primary;

        &:hover {
            background: $primary;
            color: $secondary;
        }
    }

    &.border {
        border: 2px solid $off-white;
        background: transparent;
        color: $off-white;

        &:hover {
            background: $primary;
            color: $secondary;
            border: 2px solid $primary;
        }
    }
}

.text-button {
    font-size: clamp(0.875rem, 0.835rem + 0.183vw, 1rem);
    letter-spacing: 1px;
    font-weight: 600;
    text-decoration: none;

}
